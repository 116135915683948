<template>
  <div class="tax-form">
    <v-form ref="form">
      <v-row>
        <v-col cols="8">
          <vuetify-money
              dense
              :outlined="true"
              :options="$store.state.formatSalaryNumber"
              :clearable="true"
              class="label-input input-number"
              :label="$t('Tax.form.salaryMin')"
              :rules="nameRules"
              v-model="tax_item.minimum_salary"
              :hint="`${server_errors.minimum_salary}`"
              persistent-hint
              @keydown.enter="focusNext"
          />
        </v-col>
        <v-col cols="8">
          <vuetify-money
              dense
              :outlined="true"
              :options="$store.state.formatSalaryNumber"
              :clearable="true"
              class="label-input input-number"
              :label="$t('Tax.form.salaryMax')"
              :rules="nameRules"
              v-model="tax_item.maximum_salary"
              :hint="`${server_errors.maximum_salary}`"
              persistent-hint
              @keydown.enter="focusNext"
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
              outlined
              dense
              class="label-input input-number"
              type="number"
              :label="$t('Tax.form.charge')"
              :rules="nameRules"
              v-model="tax_item.charge"
              :hint="`${server_errors.charge}`"
              persistent-hint
              @keydown.prevent.enter="validateForm"
          >
          </v-text-field>
        </v-col>

        <!--        <v-col cols="8">-->
        <!--          <v-select-->
        <!--              outlined-->
        <!--              dense-->
        <!--              class="label-input"-->
        <!--              :label="$t('Tax.form.comparison_mark')"-->
        <!--              :rules="nameRules"-->
        <!--              :items="comparisonMark"-->
        <!--              item-text="name"-->
        <!--              item-value="name"-->
        <!--              v-model="tax_item.comparison_mark"-->
        <!--              :hint="`${server_errors.comparison_mark}`"-->
        <!--              persistent-hint-->
        <!--          >-->
        <!--          </v-select>-->
        <!--        </v-col>-->
        <v-col cols="8">
          <v-btn class="btn-save-change" @click="validateForm">
            {{ $t("Tax.form.edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    tax_item: {},
  },
  data() {
    return {
      comparisonMark: [
        {
          name: "down",
        },
        {
          name: "up"
        }
      ],
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      server_errors: {
        minimum_salary: "",
        charge: "",
        maximum_salary: "",
        comparison_mark: "",

      }
    }
  },
  methods: {

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input,vuetify-money'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.UpdateItem();
        this.btnLoading = true
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    UpdateItem() {
      this.$axios.put(`admin/edit/tax`, this.tax_item, {
        params: {
          id: this.tax_item.id,
        }
      }).then((res) => {
        if (res) {
          setTimeout(() => {
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
            this.$emit("success");
            this.$emit("close");
          }, 300);
        }
        this.btnLoading = false;
        this.resetForm();
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
  created() {
    console.log(this.tax_item)
  }
}
</script>

<style scoped lang="scss">
.tax-form {
  width: 100%;
  height: 600px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
